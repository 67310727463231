@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@200&display=swap');

.App {
  text-align: center;
  font-family: 'Outfit', sans-serif !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

header {
  background-color: #333;
  color: white;
  padding: 20px;
  text-align: center;
}

main {
  padding: 20px;
  text-align: center;
}

img {
  width: 500px;
  height: 300px;
  margin-bottom: 20px;
}

footer {
  background-color: #333;
  color: white;
  padding: 10px;
  text-align: center;
}

.navbar {
  margin-bottom: 20px;
}


.card {
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: 1rem;
}

.navbar-inner {
  background:transparent;
}


canvas{
  display: block;
  height: 104vh !important;
}

#tsparticles{
  position:absolute; 
  width: 100%; 
  height: 104vh !important;
  background-image: linear-gradient(to bottom,rgba(0, 0, 0, 0.7), rgba(255, 255, 255, 0.7));
  display: block;
  left: 0;
  top: 0;
  z-index: -1;
}
#preloader {
    overflow: hidden;
    background-color: #fff;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999999;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.cover {
  color: #fffff0;
  position: relative;
  z-index: 3;
  text-align: center;
  margin-left: 5vh;
  text-shadow: 3px 0px 7px rgba(81,67,21,0.8), 
  -3px 0px 7px rgba(81,67,21,0.8), 
  0px 4px 7px rgba(81,67,21,0.8);
}

.land {
  height: 70vh;
}

.titulo {
  font-size: 3.5rem;
  font-weight: bolder;
}
.subtitulo {
  font-size: 1.8rem;
  font-weight: 300;
}

.linkText {
  color: #FFFFF0  !important;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 240, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.card-service {
  display: flex;
  height: 400px;
  justify-content: center;
  margin-top: 5rem;
  flex-wrap: wrap;
  opacity: 0.8;
  border-color: darkgoldenrod;
}


.card-img, .card-img-bottom, .card-img-top {
  width: 50% !important;
  height: auto;
}

.prefuter {
  margin-bottom: 50px;
}

.logo {
  width: 15%;
  height: auto;
  float: left;
  position: relative;
  margin-left: 10%;
}


.navbar-expand-lg .navbar-nav .nav-link {
  padding-right: 30px !important;
  padding-left: 30px !important;
}

.linkText:hover {
  background-color:transparent; /*will change background-color of element on hover */
  color: gold !important;  /*will change color of text within the element on hover */
}

.navbar-nav {
  font-weight: bold;
}

.coverbarra {
  top: 1vh;
  position: relative;
}

@media (max-width: 991px) {

  .navbar-detail {
    margin-top: 7vh;
  }

  .coverbarra {
    position: absolute;
    left: 40%;
    transform: translatex(-50%);
  }

  .coverbarra .titulo {
    transform: translatex(-8%) !important;
  }

  .coverbarra .subtitulo {
    transform: translatex(-24%) !important;
    margin-top: 3vh !important;
    font-size: 1.1rem !important;
  }

  .logo {
    position: absolute;
    left: 1% !important;
    transform: translatex(-50%);
  }

  .cover {
    margin-left: 5vh;
  }

  .navbar-toggler-icon {
    position: absolute;
    top: 7px !important;
    right: 15px;
    padding: 22px 0px 37px 0px;
    cursor: pointer;
    color: gold;
  }

  .titulo {
    text-align: center;
    transform: translatex(-56%);
    font-size: 2.4rem;
    font-weight: bolder;
    left: 50%;
    position: inherit;
    margin-top: 0;
  }
  .subtitulo {
    text-align: center;
    font-size: 1.3rem;
    font-weight: 0;
    left: 1%;
    top: 10%;
    position: inherit;
    transform: translatex(-7%);
    margin-top: 2vh;
  }

  .eq {
    margin-top: 15vh !important;
  }

  .servicios {
    margin-top: 10vh !important;
  }

  .columnas-flex {
    display: flow-root list-item !important;
    
  }


  .columna-izquierda {
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
    margin: auto !important;
    margin-top: 0vh !important;
  }

  .carousel {
    width: 100% !important;
  }

  .carousel img {
    height: 62vh !important;
    border-radius: 0px !important;
  }

  .columna-derecha {
    width: 100% !important;
    margin-top: 15vh;
  }

  .contacto-container .columna-derecha ul {
    display: inline-block;
  }

  .contacto-container .row {
    height: 50vh;
    width: auto !important;
    align-content: center !important;
  }

  .carousel-caption p {
    font-size: 2.5vh !important;
    font-weight: 900 !important;
    color: #fffff0 !important;
    text-align: center !important;
  }

  .texto-blanco {
    font-size: 1.8rem !important;
  }

  .contacto-container .columna-izquierda .titulo {
    text-align: center;
    font-size: 2.5rem;
    font-weight: bolder;
    position: inherit;
    margin-top: 0;
    transform: none;
  }

  .contacto-container .columna-izquierda .subtitulo {
    
    transform: none;
  }

  .columnas-flex {
    display: block !important;
    
  }

  .land {
    margin-top: 5vh;
  }


  .contacto-container .columna-izquierda {
    margin-left: 0vh !important;
  }

  .col-der-text {
    text-align: center !important;
    margin-top: 5vh !important;
  }

  .contacto-container .columna-derecha {
  
    font-size: 15px !important;
    font-weight: bolder;
  }

  .botonesBottom {
    right: 25px !important;
    bottom: 35px !important;
    z-index: 1;
  }
  
}


.servicios {
  margin-top: 10vh ;
}

.card-title {
  font-weight: bolder !important;
}

.equipo {
  justify-content: center;
  margin-left: auto;
}


/*carousel*/

.carousel {
  width: 85%;
}

.carousel img {
  height: 60vh !important;
  border-radius: 10px;
}

.columnas-flex {
  display: flex;
  justify-content: space-between;
  margin-top: 28vh;
}
.columna-izquierda {
  width: 80%; 
  display: flex;
  justify-content: left;
  margin: auto;
  margin-left: 0vh;
}

.asd {
  margin-top: -6px;
  color: wheat;
}

@media (max-width: 1903px) {
  .columna-izquierda {
    margin-left: 3vh;
  }

  .contacto-container .columna-derecha {
    align-items: center !important;
    font-size: larger;
  }
  
  .contacto-container .columna-izquierda {
    margin-left: -20vh;
    margin-top: 10vh;
  }

}

.columna-derecha {
  width: 58%;
  display: flex;
  justify-content: center;
}

.carousel-caption  p {
  font-size: 3vh;
  font-weight: bolder;
}


.card-title {
  font-size: 1.6rem;
}

.card-text p {
  font-size: 1.1rem;
}

.futer p {
  display: flex;
  justify-content: center;
  font-size: 0.8rem;
}

.contact {
  background-color: goldenrod;
  height: 25vh;
  width: 100% !important;
  margin-top: 20vh;
}

.eq {
  margin-top: 30vh;
}

.cols-contact {
  display: flex;
  justify-content: space-between;
  height: 100%;
  color: #fffff0;
}

.cols-contact .columna-izquierda {
  width: 0%;
}

.cols-contact .columna-derecha {
  width: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
}

.cols-contact .columna-derecha p {
  margin: auto;
  font-size: x-large;
  
}

.fainsta {
  margin-right: 10px;
}


.btn-scroll-top {
  
  z-index: 100;
  background-color: #ffc107 !important;
  color: #000000;
  border: none;
}

.btn-wps {
  margin-left: 5px;
  z-index: 100;
  color: #000000;
  border: none;
}

.botonesBottom {
  position: fixed;
  bottom: 20px;
  right: 35px;
  margin: 1px 1px 1px 1px;
}

.btn-scroll-top:focus,
.btn-scroll-top:hover {
  background-color: #ffc107 !important;
  color: #000000;
}


.contacto-container {
  background-color: #ffc107;
  color: #fffff0;
}

.contacto-container .row {
  height: 40vh;
  width: 100%;
}

.contacto-container .col {
  padding: 0 15px;
}

.contacto-container .columna-derecha {
  
  font-size: 21px;
  font-weight: bolder;
}

.contacto-container .columna-izquierda {
  margin-left: -20vh ;
  margin-top: 12vh;
  text-align: left;
}

.contacto-container .col:first-child {
  border-right: 1px solid #fff;
}

.contacto-container .columna-derecha li {
  text-align: left;
  list-style: none;
}

.contacto-container .columna-derecha li::before {
  content: none;
}

.font {
  margin-right: 10px;
}

.carousel-caption p {
  font-size: 3.5vh;
  font-weight: 900;
  color: #fffff0;
  text-transform: uppercase;
  text-shadow: 3px 0px 7px rgba(81,67,21,0.8), 
  -3px 0px 7px rgba(81,67,21,0.8), 
  0px 4px 7px rgba(81,67,21,0.8);
  text-align: left;
}

.navbar {
  text-shadow: 3px 0px 7px rgba(81,67,21,0.8), -3px 0px 7px rgba(81,67,21,0.8), 0px 4px 7px rgba(81,67,21,0.8);
}

.texto-blanco {
  font-size: 2.5rem;
  font-weight: bolder;
}

.texto-equipo {
  font-size: 2.5rem;
}

.card {
  margin-top: 5vh;
}


.coverbarra .titulo {
  text-align: center;
  transform: translatex(-25%);
  font-size: 1.5rem;
  font-weight: bolder;
  position: inherit;
  margin-top: 0;
  color: whitesmoke;

}

.coverbarra .subtitulo {
  text-align: center;
  font-size: 1.2rem;
  font-weight: 100;
  position: inherit;
  color: whitesmoke;
  transform: translatex(-16%);
  margin-top: -1vh;

}

.col-der-text {
  text-align: left;
}

.btn .btn-warning {
  color: white !important;
}

.asd {
  color: white !important;
  font-weight: bolder !important;
}