.card-hover:hover {
    transform: scale(1.05);
    transition: transform 0.3s ease-in-out;
  }
  
.fade-in {
    opacity: 0;
    transition: opacity 0.3s;
  }
  
.fade-in.show {
    opacity: 1;
  }